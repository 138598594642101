import './PortfolioDetalhe.css';

import { Component } from 'react';

import {Link} from 'react-router-dom'
import LazyLoad from "react-lazyload";

class PortfolioDetalhe extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            propriedades: {
                titulo: "",
                fichaTecnica: {},
                imagens: []
            }
        }
    }

    componentDidMount() {
        this.getPropriedades();
    };

    render() {
        return (
            <section id="portfolio" className="portfolioSection">
                
                <div className="container" style={{paddingTop: "50px"}}>
                    <div className="jumbotron text-center">
                        <h1 className="jumbotron-heading text-uppercase">{this.state.propriedades.titulo}</h1>
                        <hr />
                    </div>
                </div>

                <article className="gallery">
                    {this.state.propriedades.imagens.map(imagem => (
                        <LazyLoad key={`${imagem.src}`} >
                            <figure className="gallery-image">
                                <img alt={this.state.propriedades.titulo} height="1600" src={`${imagem.src}`} width="1000" />
                            </figure>
                        </LazyLoad>
                    ))}
                </article>
                <div className="container ficha-tecnica">
                    <h1 className="titulo-menor">Ficha técnica</h1>
                    <p className="media-body pb-3 mb-0 small lh-125">
                        {this.state.propriedades.fichaTecnica.tipo}
                        <strong className="d-block text-gray-dark">{this.state.propriedades.fichaTecnica.executor}</strong>
                    </p>

                    <p className="media-body pb-3 mb-0 small lh-125">
                        Área do Projeto
                        <strong className="d-block text-gray-dark">{this.state.propriedades.fichaTecnica.area}</strong>
                    </p>

                    <p className="media-body pb-3 mb-0 small lh-125">
                        Localidade
                        <strong className="d-block text-gray-dark">{this.state.propriedades.fichaTecnica.localidade}</strong>
                    </p>

                    <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
                        Fotos
                        <strong className="d-block text-gray-dark">{this.state.propriedades.fichaTecnica.fotos}</strong>
                    </p>
                </div>

                <div className="container">
                    <div className="jumbotron text-center sem-margin-bottom">
                        <h1 className="jumbotron-heading titulo-menor text-uppercase">O Seu projeto pode ser o próximo</h1>
                        <hr />
                        <div className="p50">
                            <p className="text-uppercase">
                                Se você quiser conhecer a nossa equipe ou discutir algum projeto, marque uma visita 
                                e venha tomar um café conosco.
                            </p>
                        </div>

                        <a href="/#contato" className="btn custom text-uppercase">Envie uma Mensagem</a>

                    </div>
                </div>

                <div className="container text-center">
                    <Link className="back text-uppercase" to="/#portfolio">Voltar</Link>
                    <div className="jumbotron ">
                    </div>
                </div>
            </section>
        );
    }

    getPropriedades = () => {
        const response = fetch(process.env.PUBLIC_URL + "/img/projetos/" + this.props.match.params.diretorio + "/propriedades.json");
        response.then((resp) => {return resp.json()})
                .then((data) => {
                    data.imagens.forEach((imagem) => {
                        imagem.src = process.env.PUBLIC_URL + "/img/projetos/" + this.props.match.params.diretorio + "/" + imagem.nomeArquivo;
                        delete imagem.nomeArquivo;
                    });
                    this.setState({propriedades: data});
                });
        
    }
}

export default PortfolioDetalhe;
