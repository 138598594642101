import React, { Component } from 'react';

import './App.css';

import Router from './components/router/Router'

class App extends Component {
    render() {
        return (
            <Router />
        );
    }
}

export default App;
