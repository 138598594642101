import './Header.css';

// import logoBg from '../../img/logo-large.png';
// import logo from '../../img/logo.png';

function Header() {
  return (
    <div className="view">
        <div className="mask rgba-black-light flex-center center">
            {/* <img className="centerLogo img-fluid" src={logoBg} /> */}
        </div>
    </div>
  );
}
export default Header;

