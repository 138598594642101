import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'

import About from '../about/About';
import Header from '../header/Header';
import Persons from '../persons/Persons';
import Services from '../services/Services';
import Portfolio from '../portfolio/Portfolio';
import Contato from '../contato/Contato';


class Principal extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return(
            <React.StrictMode>
                <Header />
                <About />
                <Persons />
                <Services />
                <Portfolio />
                <Contato />
            </React.StrictMode>
        );
    }   
}

export default withRouter(Principal)