import './About.css';

function About() {
    return (
        <section id="about" className="aboutSection">
            <div className="container">
                <div className="jumbotron text-center">
                    <h1 className="jumbotron-heading text-uppercase">Quem Somos</h1>
                    <hr />
                    <p className="lead text-muted">
                        Acreditamos que a arquitetura está diretamente relacionada com a qualidade de vida,
                        ela é capaz de mudar a percepção das pessoas sobre os lugares, tanto no âmbito da 
                        cidade quanto dos ambientes de trabalho e residenciais. Através dela, conseguimos 
                        qualificar espaços, e proporcionar experiências valorosas aos usuários.
                    </p>
                    <p className="lead text-muted">
                        Com foco nas pessoas e na funcionalidade, atentando às demandas específicas de cada 
                        situação, conseguimos como resultado, espaços únicos e personalizados, que traduzem 
                        a essência e ressaltam o aspecto humano de cada projeto. Nosso processo de trabalho 
                        é colaborativo e visa através da troca com o cliente criar o espaço ideal.
                    </p>
                    <p className="lead text-muted">
                        Acreditamos na criação de valor através da Arquitetura!
                    </p>
                </div>
            </div>
        </section>
  );
}
export default About;
