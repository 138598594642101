import React, { Component } from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import HeaderBar from '../header-bar/HeaderBar'
import PortfolioDetalhe from '../portfolio-detalhe/PortfolioDetalhe'
import Principal from '../principal/Principal'

import ErrorComponent from '../todo-app/ErrorComponent'

class RouterComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return(
            <Router>
                <>
                    <HeaderBar />
                    <Switch>
                        <Route exact path="/" component={Principal} />
                        <Route exact path="/p/:diretorio" component={PortfolioDetalhe} />
                        <Route component={ErrorComponent} />
                    </Switch>
                    {/* <FooterComponent /> */}
                </>
            </Router>
        );
    }
}

export default RouterComponent;