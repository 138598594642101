import './Persons.css';


function Persons() {
    return (
        <section id="persons" className="personsSection">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card profile-cards profile-card-1">
                            <div className="card-img-block cropped">
                                <img alt="carol" className="card-img-top" src={process.env.PUBLIC_URL + '/img/profile/carol.jpg'} />
                            </div>
                            <div className="card-body pt-0">
                                <h5 className="card-title">Carol Minetto</h5>
                                <p className="card-text">
                                    Arquiteta e Urbanista (2018) e pós graduada em Gestão de Projetos e Obras (2020) pela Uniritter. 
                                    No decorrer da sua formação adquiriu experiência na colaboração de projetos arquitetônicos 
                                    corporativos e residenciais em diferentes escalas.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card profile-cards profile-card-2">
                            <div className="card-img-block cropped">
                                <img alt="elis" className="card-img-top" src={process.env.PUBLIC_URL + '/img/profile/elis.jpg'} />
                            </div>
                            <div className="card-body pt-0">
                                <h5 className="card-title">Elis Escouto</h5>
                                <p className="card-text">
                                    Arquiteta e Urbanista pela Uniritter (2010), morou em Barcelona onde cursou 
                                    o Master Desarrollo Urbano y Territorial, pela UPC. Ao longo da sua trajetória 
                                    profissional realizou projetos arquitetônicos corporativos, comercias e residenciais.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card profile-cards profile-card-3">
                            <div className="card-img-block cropped">
                                <img alt="rochele" className="card-img-top" src={process.env.PUBLIC_URL + '/img/profile/rochele.jpg'} />
                            </div>
                            <div className="card-body pt-0">
                                <h5 className="card-title">Rochele Lyrio</h5>
                                <p className="card-text">
                                    Arquiteta e Urbanista pela Uniritter (2011), ao longo de sua vida acadêmica esteve 
                                    a frente do diretório. Realizou projetos arquitetônicos corporativos, comercias 
                                    e residências. Atualmente faz parte da diretoria do IAB-RS.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Persons;
