import './HeaderBar.css';
import './nav-bar.js';

import logo from '../../img/logo.png';
import { Component } from 'react';

class HeaderBar extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <header>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark indigo">
                    <div className="mx-auto d-sm-flex d-block flex-sm-nowrap">
                        <a className="navbar-brand" href="/"><img className="nav-logo" src={logo} /></a>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mx-auto">
                                <li className="nav-item">
                                    <a className={`nav-link ${window.location.pathname.length > 3 ? "no-white-header-font" : ""}`} href="/#about">Sobre</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${window.location.pathname.length > 3 ? "no-white-header-font" : ""}`} href="/#services">Serviços</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${window.location.pathname.length > 3 ? "no-white-header-font" : ""}`} href="/#portfolio">Portfólio</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default HeaderBar;
