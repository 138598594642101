import './Services.css';

function Services() {
    return (
        <section id="services" className="serviceSection">
            <div className="container">
                <div className="jumbotron jumbotron-reduce text-center">
                    <h1 className="jumbotron-heading text-uppercase">Serviços</h1>
                    <hr />
                </div>



                <div className="row">
                    <div className="col-md-7 text-left">
                        <h3 className="jumbotron-heading text-uppercase service-header">Consultoria</h3>
                        <p className="font-weight-light">
                            Atuamos com o objetivo de solucionar uma demanda específica do
                            cliente. Tendo como exemplo, a assessoria na escolha de imóveis,
                            vistorias, escolha de revestimentos e produtos, escolha de mobiliários
                            ou outro segmento. Atendemos de forma personalizada conforme a
                            demanda.
                        </p>
                    </div>
                </div>

                <div className="row justify-content-end">
                    <div className="col-md-7 text-right">
                        <h3 className="jumbotron-heading text-uppercase service-header">Projetos Arquitetônicos de Edificações e Interiores</h3>
                        <p className="font-weight-light">
                            Trabalhamos com projetos residenciais, comerciais e corporativos.
                            Através de um processo colaborativo, realizado com a aprovação em
                            etapas junto ao cliente, entregamos um projeto completo, contemplando
                            todos os detalhamentos e orçamentos dos itens propostos, pronto para
                            serem executados.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-7 text-left">
                        <h3 className="jumbotron-heading text-uppercase service-header">Acompanhamento de Obras</h3>
                        <p className="font-weight-light">
                            Visitas em obra previamente agendadas, para esclarecimentos de dúvidas
                            e soluções pontuais dos fornecedores.
                        </p>
                    </div>
                </div>

                <div className="row justify-content-end">
                    <div className="col-md-7 text-right">
                        <h3 className="jumbotron-heading text-uppercase service-header">Gerenciamento de Obras</h3>
                        <p className="font-weight-light">
                            Gerenciamos a logística de todas as atividades, fornecedores e
                            serviços envolvidos, através de cronogramas, planejamento financeiro
                            da obra, assessoria e acompanhamento técnico, com visitas regulares
                            para fiscalização dos serviços. Finalizamos com a entrega de material
                            técnico, contendo além de todos os projetos revisados, relatório da
                            obra e manual do proprietário.
                        </p>
                    </div>
                </div>
            </div>
        </section>
  );
}
export default Services;
