import './Portfolio.css';

import PortfolioService from "../../api/PortfolioService";

import LazyLoad from "react-lazyload";
import { Component } from 'react';

class Portfolio extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            capas: []
        }
    }

    componentDidMount() {
        this.listarCapas();
    };

    render() {
        return (
            <section id="portfolio" className="portfolioSection">
                <div className="container">
                    <div className="jumbotron text-center">
                        <h1 className="jumbotron-heading text-uppercase">Portfólio</h1>
                        <hr />
                    </div>        
                </div>
                <div className="portfolio-warp">
                    {this.state.capas.map(projeto => (
                        <LazyLoad key={`${projeto.nomePasta}`} height={200} offset={200} >
                                <div className="single-portfolio set-bg" style={{backgroundImage: `url("${process.env.PUBLIC_URL}/img/projetos/${projeto.nomePasta}/01.jpg")`}}>
                                    <a className="portfolio-info portfolio-link" href={`p/${projeto.nomePasta}`}>
                                        <div className="pfbg set-bg" style={{backgroundImage: `url("${process.env.PUBLIC_URL}/img/projetos/${projeto.nomePasta}/01.jpg")`}}></div>
                                        <h5>{projeto.titulo}</h5>
                                        <p>{projeto.tipo}</p>
                                    </a>
                                </div>
                        </LazyLoad>
                    ))}
                </div>
            </section>
        );
    }

    listarCapas = () => {
        PortfolioService.listarCapas()
                        .then(resp => this.handleSuccessfulResponse(resp));
    }

    handleSuccessfulResponse = (resp) => {
        this.setState({capas: resp.data.projetos});
    }
}

export default Portfolio;
