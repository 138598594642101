import './Contato.css';

import { Component } from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik'

import ContatoService from "../../api/ContatoService";

class Contato extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            nome: '',
            email: '',
            telefone: '',
            preferencia: '',
            chegou: '',
            mensagem: '',
            enviado: false
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        this.setState({ enviado: false });
    }

    handleEnviarEmail(data) {
        ContatoService.enviarEmail(data)
                      .then(resp => this.setState({ enviado: true }));
    }

    validate(values) {
        let errors = {};
        if(!values.nome) {
            errors.nome = "Digite um nome"
        }

        if(!values.email) {
            errors.email = "Digite um e-mail"
        }

        if(!values.telefone) {
            errors.telefone = "Digite um telefone"
        }

        if(!values.preferencia) {
            errors.preferencia = "Selecione uma opção"
        }
        return errors;
    }

    render() {

        let {  nome, email, telefone, preferencia, chegou, mensagem } = this.state;

        return (
            <section id="contato" className="contatoSection">

                <div className="container">
                    <div className="jumbotron text-center">
                        <h1 className="jumbotron-heading text-uppercase">Contato</h1>
                        <hr />

                        {this.state.enviado && 
                            <div class="alert alert-success" role="alert">
                                Obrigado. Faremos contato em breve.
                            </div>
                        }

                        <Formik initialValues={{  nome, email, telefone, preferencia, chegou, mensagem }}
                                onSubmit={(values, {resetForm}) => {
                                    this.handleEnviarEmail(values);
                                    resetForm({ values: ''});
                                }}
                                validateOnChange={false}
                                validateOnBlur={false}
                                validate={this.validate}
                                enableReinitialize={true}>
                            {(values, errors) => (
                                <Form className="contato-form">
                                    <div className="form-row text-left">
                                        <div className="form-group col-md-6">
                                            <Field name="nome">
                                                {({
                                                    field, // { name, value, onChange, onBlur }
                                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                    meta,
                                                }) => (
                                                    <>
                                                        <label className={`${meta.touched && meta.error ? 'text-danger' : ''}`}
                                                               htmlFor="nome">
                                                            Nome*
                                                        </label>
                                                        <input type="text"
                                                            className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                                                            placeholder="Nome" {...field} />
                                                        {meta.touched && meta.error && (
                                                        <div className="text-danger">{meta.error}</div>
                                                        )}
                                                    </>
                                                )}
                                            </Field>
                                        </div>

                                        <div className="form-group col-md-6">
                                            <Field name="email">
                                                {({
                                                    field, // { name, value, onChange, onBlur }
                                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                    meta,
                                                }) => (
                                                    <>
                                                        <label className={`${meta.touched && meta.error ? 'text-danger' : ''}`}
                                                               htmlFor="email">
                                                            E-mail*
                                                        </label>
                                                        <input type="text"
                                                            className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                                                            placeholder="E-mail" {...field} />
                                                        {meta.touched && meta.error && (
                                                        <div className="text-danger">{meta.error}</div>
                                                        )}
                                                    </>
                                                )}
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="form-row text-left">
                                        <div className="form-group col-md-6">
                                            <Field name="telefone">
                                                {({
                                                    field, // { name, value, onChange, onBlur }
                                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                    meta,
                                                }) => (
                                                    <>
                                                        <label className={`${meta.touched && meta.error ? 'text-danger' : ''}`}
                                                               htmlFor="telefone">
                                                            Telefone*
                                                        </label>
                                                        <input type="text"
                                                            className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                                                            placeholder="Telefone" {...field} />
                                                        {meta.touched && meta.error && (
                                                        <div className="text-danger">{meta.error}</div>
                                                        )}
                                                    </>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <Field name="preferencia">
                                                {({
                                                    field, // { name, value, onChange, onBlur }
                                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                    meta,
                                                }) => (
                                                    <>
                                                        <label className={`${meta.touched && meta.error ? 'text-danger' : ''}`}
                                                               htmlFor="preferencia">
                                                            Preferência de Contato*
                                                        </label>
                                                        <select type="text"
                                                                className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                                                                placeholder="Preferência de Contato" {...field}>
                                                            <option value="" defaultValue >Selecione</option>
                                                            <option value="whatsapp">WhatsApp</option>
                                                            <option value="e-mail">E-mail</option>
                                                            <option value="telefone">Telefone</option>
                                                        </select>
                                                        {meta.touched && meta.error && (
                                                        <div className="text-danger">{meta.error}</div>
                                                        )}
                                                    </>
                                                )}
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="form-row text-left">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="chegou">Como você chegou até aqui?</label>
                                            <Field type="text"
                                                   className="form-control"
                                                   id="chegou"
                                                   placeholder="Como você chegou até aqui?"
                                                   name="chegou" />
                                        </div>
                                    </div>

                                    <div className="form-row text-left">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="mensagem">Mensagem</label>
                                            <Field as="textarea"
                                                   type="textbox"
                                                   className="form-control"
                                                   id="mensagem"
                                                   rows="4"
                                                   placeholder="Deixe uma mensagem..."
                                                   name="mensagem" />
                                        </div>
                                    </div>

                                    <div className="text-left">
                                        <button type="submit" className="btn custom text-uppercase">Enviar</button>
                                    </div>
                                </Form>        
                            )}
                        </Formik>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contato;
